import React, { useState, useCallback, useEffect } from 'react'
import { HamburgerSpring } from 'react-animated-burgers'

const HamburgerButton = ({ handleClick }) => {
  const [isActive, setIsActive] = useState(false)
  const [isMounted, setIsMounted] = useState(false)


  const toggleButton = useCallback(
    () => setIsActive(prevState => !prevState),
    [],
  )

  useEffect(() => {
    setIsMounted(true)
  },[])

  useEffect(() => {
    isMounted && handleClick()
  },[isActive])

  return (
    <HamburgerSpring
    buttonWidth={20}
    barColor="#f5f3d2"
      {...{ isActive, toggleButton }}
    />
  )
}

export default HamburgerButton