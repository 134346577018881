import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./layout.css";
import { Link } from 'gatsby'
import { myContext } from './PageContext';
import HamburgerButton from './HamburgerButton'
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'

export default function Layout(props) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const context = useContext(myContext);
  const [navClass, setNavClass] = useState('main-nav')

  useEffect(() => {
    context.mainNavOff()
  }, []);

  const tabable = navClass ===  'main-nav' ? '-1' : '0'

  function handleClick(){
      if (navClass === 'main-nav') {
        setTimeout(() => context.toggleMainNav(), 0)
        setTimeout(() => setNavClass('main-nav animate'), 0)
      } else {
        setTimeout(() => context.toggleMainNav(), 0)
        setNavClass('main-nav')
      }
    }
  
  

  return (
    <myContext.Consumer>
    {context => (
    <div Style={context.mainNav ? 'overflow: hidden; height: 100vh; max-height: -webkit-fill-available;' : null} className="main-grid">
      <header>

        <h1 classname="title"><Link to="/">{data.site.siteMetadata.title}</Link></h1>
        <div  style={{position: "absolute", right: "1rem"}}>
          <HamburgerButton handleClick={handleClick}/>
        </div>
        <nav className={navClass}>
          <ul>
            <li><Link onClick={() => context.sideNavOff()} to="/all" tabindex={tabable}>Work</Link></li>
            <li><Link to="/about" tabindex={tabable}>About</Link></li>
            <li><Link to="/statements" tabindex={tabable}>Statements</Link></li>
            <li><Link to="/media" tabindex={tabable}>Media</Link></li>
            <li><Link to="/contact" tabindex={tabable}>Contact</Link></li>
          </ul>
        </nav>
      </header>
      {context.sideNav && props.children.length > 1 ? props.children.filter(child => child.type !== 'main') : null }
      {props.children.length > 0 ? props.children.filter(child => child.type === 'main') : props.children}
      {/* <div className={context.mainNav ? 'overlay overlay-active' : 'overlay'}></div> */}
      <footer>
        <small>© {new Date().getFullYear()} Alan Macdonald</small>
        <div style={{marginTop: '1rem'}}>
          <a href="https://www.instagram.com/alanmacdonaldart/" alt="instagram link" target="blank"><FaInstagram /></a>
          <a href="https://www.facebook.com/alanmacdonaldart/" alt="facebook link" target="blank"><FaFacebookSquare /></a>
        </div>
      </footer>
    </div>
    )}
    </myContext.Consumer>
  );
}
